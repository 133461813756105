import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, UserSession } from '../services/auth.service';

import { map, shareReplay, switchMap } from 'rxjs/operators';
import { PermissionsService } from '../../admin/services/permissions.service';

const determineAccess = (
  session: UserSession,
  availablePermissions: string[],
  permissionsToCheck: string[]
): boolean => {
  return (
    session &&
    session.isValid() &&
    availablePermissions.some((group) =>
      permissionsToCheck.some((partialName) => group.startsWith(partialName))
    )
  );
};

export class SectionGuard  {
  hasAccess: Observable<boolean>;

  constructor(
    authService: AuthService,
    permissionsService: PermissionsService,
    permissionsToCheck: string[],
    hasAccessFn = determineAccess
  ) {
    this.hasAccess = authService.onUserSession.pipe(
      switchMap((session) =>
        permissionsService
          .getUserPermissions()
          .pipe(
            map((permissions) =>
              hasAccessFn(session, permissions, permissionsToCheck)
            )
          )
      ),
      shareReplay()
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.hasAccess;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasAccess;
  }
}
