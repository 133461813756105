export const ROUTE_NAMES = {
  PRODUCT_CAPTURE: 'product-capture',
  LIFECYCLE_EVENTS: 'lifecycle-events',
  STATIC_DATA: 'static-data',
  RELEASE_MANAGEMENT: 'release-management',
  ADMIN: 'admin',
  ENRICHMENT: 'enrichment',
  INGESTION: 'ingestion',
  RULES:'rules',
  VALIDATION: 'validation',
  NOT_FOUND: 'not-found'
};
