import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dc-loading',
  templateUrl: './dc-loading.component.html',
  styleUrls: ['./dc-loading.component.scss'],
})
export class DcLoadingComponent implements OnInit {
  @Input() lightBackground: boolean = false;
  @Input() translucentBackground: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
