import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from './shared/guards/admin-guard.service';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
  },
  { path: '', redirectTo: '/product-capture', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthO3RoutingModule {}
