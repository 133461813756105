export const STATUS_TYPE = [
  { id: 'Draft', displayValue: 'Draft' },
  { id: 'In Review', displayValue: 'In Review' },
  { id: 'Partially Complete', displayValue: 'Partially Complete' },
  { id: 'Complete', displayValue: 'Complete' },
  { id: 'Deleted', displayValue: 'Deleted' },
];

export enum ProductRecordStatus {
  DRAFT = 'Draft',
  IN_REVIEW = 'In Review',
  PARTIALLY_COMPLETE = 'Partially Complete',
  COMPLETE = 'Complete',
  DELETED = 'Deleted',
}
