import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsResponse } from '../model/settings-responses';

@Injectable({
  providedIn: 'root',
})
export class AuthSettingsService {
  constructor(private httpClient: HttpClient) {}

  async loadSettings(): Promise<SettingsResponse> {
    const HOSTNAME = window.location.hostname.includes('localhost')
    ? 'dev.o3.inspire.direct'
    : window.location.hostname;
    const settingsUrl = 'https://ps.' + HOSTNAME + '/settings/authServers'
    return this.httpClient
      .get(settingsUrl, {
        headers: {
          Accept: 'application/json',
        },
      })
      .toPromise()
      .then(response => {
        return response as SettingsResponse
      })
  }
}
