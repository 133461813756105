import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LogoColors } from './logo-colors.const';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnChanges {

  @Input()
  environment: string;

  mainColor = LogoColors.DEFAULT.mainColor;
  secondaryColor = LogoColors.DEFAULT.secondaryColor;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if ('environment' in changes) {
      if (Object.keys(LogoColors).indexOf(changes.environment?.currentValue?.toUpperCase()) > -1) {
        const env = this.environment.toUpperCase();
        this.mainColor = LogoColors[env].mainColor ?? LogoColors.DEFAULT.mainColor;
        this.secondaryColor = LogoColors[env].secondaryColor ?? LogoColors.DEFAULT.secondaryColor;
      }
    }
  }

}
