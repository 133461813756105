import { Observable, ReplaySubject, of } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

import { ApiService } from '../../shared/services/api.service';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from '../model/permission';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth.service';

const HOSTNAME_O3 = environment.api.o3;

export interface PermissionResponse {
  total: number;
  permissions: Array<Permission>;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private _permissionsCache$ = new ReplaySubject<Array<string>>(1);

  constructor(private apiService: ApiService, authService: AuthService) {
    authService.onUserSession
      .pipe(
        concatMap(() =>
          this.apiService.get<Array<string>>(`user/permissions`, HOSTNAME_O3)
        )
      )
      .subscribe((permissions) => this._permissionsCache$.next(permissions));
  }

  addPatchPermission(permission: Permission): Observable<Permission> {
    return this.apiService.post(`permission`, permission, HOSTNAME_O3);
  }

  getPermissions(): Observable<PermissionResponse> {
    return this.apiService.get<Permission[]>(`permissions`, HOSTNAME_O3).pipe(
      map((permissions) => {
        return {
          total: permissions.length,
          permissions,
        } as PermissionResponse;
      })
    );
  }

  getUserPermissions(): Observable<Array<string>> {
    return this._permissionsCache$;
  }

  getPermissionsByRole(roleKey: string): Observable<Permission[]> {
    const params = new HttpParams().set('groupKey', roleKey);
    return this.apiService.get(
      `group-permission?${params.toString()}`,
      HOSTNAME_O3
    );
  }

  getPermission(key: string): Observable<Permission> {
    return this.getPermissions().pipe(
      switchMap((response) => {
        return of(
          response.permissions.find((permission) => permission.key === key)
        );
      })
    );
  }

  delete(permissionKey: string): Observable<Permission> {
    return this.apiService.post(
      `permission/delete`,
      { permissionKey: permissionKey },
      HOSTNAME_O3
    );
  }
}
