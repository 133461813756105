import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

import { Observable, forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ProductsAndEventsGuard } from 'src/app/auth-o3/shared/guards/products-and-events-guard.service';
import { ReleaseManagementGuard } from 'src/app/auth-o3/shared/guards/release-management-guard.service';
import { StaticDataGuard } from 'src/app/auth-o3/shared/guards/static-data-guard.service';
import { AuthService } from 'src/app/auth-o3/shared/services/auth.service';
import { AdminGuard } from '../../../shared/guards/admin-guard.service';
import { DataIngestionGuard } from 'src/app/auth-o3/shared/guards/data-ingestion-guard.service';
import { RulesProcessorGuard } from 'src/app/auth-o3/shared/guards/rules-processor-guard.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './dc-navbar.component.html',
  styleUrls: ['./dc-navbar.component.scss'],
})
export class DcNavbarComponent implements OnInit {
  @Input() user: string;
  @Input() email: string;
  @Input() loggedInAsDistributor: boolean;
  @Input() navigating: boolean;
  @Output() signOutEmitter: EventEmitter<any> = new EventEmitter();
  @Output() loadedEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('userMenu') userMenu: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  public activeRoute: string;
  public userPanelCollapsed;
  public navbarMenuCollapsed;

  environment$: Observable<string>;
  loading$: Observable<boolean[]>;

  hasAccess = {};

  constructor(
    router: Router,
    public adminGuard: AdminGuard,
    public releaseManagementGuard: ReleaseManagementGuard,
    public productsAndEventsGuard: ProductsAndEventsGuard,
    public staticDataGuard: StaticDataGuard,
    public dataIngestionGuard: DataIngestionGuard,
    public rulesProcessorGuard: RulesProcessorGuard,
    private renderer: Renderer2,
    private authService: AuthService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof RouterEvent) {
        this.navigationInterceptor(event);
      }
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.userMenu.nativeElement.contains(e.target) &&
        !this.toggleButton.nativeElement.contains(e.target)
      ) {
        this.userPanelCollapsed = true;
      }
    });

    this.environment$ = this.authService.getEnvironmentName();
    this.loading$ = forkJoin([
      this.releaseManagementGuard.hasAccess.pipe(take(1)),
      this.productsAndEventsGuard.hasAccess.pipe(take(1)),
      this.staticDataGuard.hasAccess.pipe(take(1)),
      this.dataIngestionGuard.hasAccess.pipe(take(1)),
      this.rulesProcessorGuard.hasAccess.pipe(take(1)),
      this.adminGuard.hasAccess.pipe(take(1)),
    ]).pipe(
      tap(
        ([
          releaseManagement,
          productsAndEvents,
          staticData,
          dataIngestion,
          rulesProcessor,
          admin,
        ]) => {
          this.hasAccess = {
            releaseManagement,
            productsAndEvents,
            staticData,
            dataIngestion,
            rulesProcessor,
            admin,
          };

          this.loadedEmitter.emit();
        }
      )
    );
  }

  ngOnInit(): void {
    this.navbarMenuCollapsed = true;
    this.userPanelCollapsed = true;
  }

  navbarMenuButton(): void {
    this.navbarMenuCollapsed = !this.navbarMenuCollapsed;
    this.userPanelCollapsed = true;
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.navigating = true;
      this.activeRoute = undefined;
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
    }
    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.navigating = false;
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
    }
    if (event instanceof ActivationEnd) {
      this.activeRoute = event.snapshot.routeConfig.path;
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
    }
  }

  signOut(): void {
    this.signOutEmitter.emit();
  }
}
