<div
  class="outerLoadingContainer"
  [ngClass]="{'light': lightBackground, 'translucent': translucentBackground}"
>
  <div class="content-container">
    <div class="loadingContainer">
      <div class="marker"></div>
      <div class="marker" style="animation-delay: 0.05s"></div>
      <div class="marker" style="animation-delay: 0.1s"></div>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
