import { ActivatedRouteSnapshot, Route, RouterModule, RouterStateSnapshot, Routes, UrlSegment } from '@angular/router';

import { AdminGuard } from './auth-o3/shared/guards/admin-guard.service';
import { ReleaseManagementGuard } from './auth-o3/shared/guards/release-management-guard.service';
import { NgModule, inject } from '@angular/core';
import { ProductsAndEventsGuard } from './auth-o3/shared/guards/products-and-events-guard.service';
import { StaticDataGuard } from './auth-o3/shared/guards/static-data-guard.service';
import { ROUTE_NAMES } from './app-routes.const';
import { DataIngestionGuard } from './auth-o3/shared/guards/data-ingestion-guard.service';
import { RulesProcessorGuard } from './auth-o3/shared/guards/rules-processor-guard.service';

const routes: Routes = [
  {
    path: 'auth-o3',
    loadChildren: () =>
      import('./auth-o3/auth-o3.module').then((m) => m.AuthO3Module),
    canLoad: [(router: Route, segments: UrlSegment[]) => inject(AdminGuard).canLoad(router, segments)],
    canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AdminGuard).canActivate(activatedRoute, state)],
  },
  {
    path: ROUTE_NAMES.PRODUCT_CAPTURE,
    loadChildren: () =>
      import('./product-capture/product-capture.module').then(
        (m) => m.ProductCaptureModule
      ),
    canLoad: [(router: Route, segments: UrlSegment[]) => inject(ProductsAndEventsGuard).canLoad(router, segments)],
    canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ProductsAndEventsGuard).canActivate(activatedRoute, state)],
  },
  {
    path: ROUTE_NAMES.LIFECYCLE_EVENTS,
    loadChildren: () =>
      import('./lifecycle-events/lifecycle-events.module').then(
        (m) => m.LifecycleEventsModule
      ),
    canLoad: [(router: Route, segments: UrlSegment[]) => inject(ProductsAndEventsGuard).canLoad(router, segments)],
    canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ProductsAndEventsGuard).canActivate(activatedRoute, state)],
  },
  {
    path: ROUTE_NAMES.STATIC_DATA,
    loadChildren: () =>
      import('./static-data/static-data.module').then(
        (m) => m.StaticDataModule
      ),
    canLoad: [(router: Route, segments: UrlSegment[]) => inject(StaticDataGuard).canLoad(router, segments)],
    canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(StaticDataGuard).canActivate(activatedRoute, state)],
  },
  {
    path: ROUTE_NAMES.RELEASE_MANAGEMENT,
    loadChildren: () =>
      import('./release-management/release-management.module').then(
        (m) => m.ReleaseManagementModule
      ),
    canLoad: [(router: Route, segments: UrlSegment[]) => inject(ReleaseManagementGuard).canLoad(router, segments)],
    canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ReleaseManagementGuard).canActivate(activatedRoute, state)],
  },
  {
    path: ROUTE_NAMES.ENRICHMENT,
    loadChildren: () =>
      import('./data-enrich/data-enrich.module').then(
        (m) => m.DataEnrichModule
      ),
    canLoad: [(router: Route, segments: UrlSegment[]) => inject(DataIngestionGuard).canLoad(router, segments)],
    canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(DataIngestionGuard).canActivate(activatedRoute, state)],
  },
  {
    path: ROUTE_NAMES.INGESTION,
    loadChildren: () =>
      import('./ingestion/ingestion.module').then((m) => m.IngestionModule),
    canLoad: [(router: Route, segments: UrlSegment[]) => inject(DataIngestionGuard).canLoad(router, segments)],
    canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(DataIngestionGuard).canActivate(activatedRoute, state)],
  },

    {
    path: ROUTE_NAMES.RULES,
    loadChildren: () =>
      import('./rules/rules.module').then((m) => m.RulesModule),
   canLoad: [(router: Route, segments: UrlSegment[]) => inject(RulesProcessorGuard).canLoad(router, segments)],
   canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(RulesProcessorGuard).canActivate(activatedRoute, state)],
  },
  {
    path: ROUTE_NAMES.VALIDATION,
    loadChildren: () =>
      import('./validation/validation.module').then((m) => m.ValidationModule),
   canLoad: [(router: Route, segments: UrlSegment[]) => inject(RulesProcessorGuard).canLoad(router, segments)],
   canActivate: [(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(RulesProcessorGuard).canActivate(activatedRoute, state)],
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
