import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { SectionGuard } from './section-guard.service';
import { PermissionsService } from '../../admin/services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard extends SectionGuard {
  constructor(
    authService: AuthService,
    permissionsService: PermissionsService
  ) {
    super(authService, permissionsService, [], () => true);
  }
}
