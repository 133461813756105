import { AuthService, UserSession } from './auth.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private session: UserSession;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.authService.onUserSession.subscribe((session: UserSession) => {
      this.session = session;
    });
  }

  get<T>(path: string, apiRoot: string): Observable<T> {
    return this.httpClient.get<T>(apiRoot + path, this.getOptions());
  }

  getWithParams<T>(path: string, params: any, apiRoot: string): Observable<T> {
    return this.httpClient.get<T>(apiRoot + path, {
      ...this.getOptions(),
      params,
    });
  }

  delete<T>(path: string, body: any, apiRoot: string): Observable<T> {
    return this.httpClient.delete<T>(
      apiRoot + path,
      this.getDeleteOptions(body)
    );
  }

  deleteNoBody<T>(path: string, apiRoot: string): Observable<T> {
    return this.httpClient.delete<T>(apiRoot + path, this.getOptions());
  }

  post<T, R>(path: string, payload: T, apiRoot: string): Observable<R> {
    return this.httpClient.post<R>(apiRoot + path, payload, this.getOptions());
  }

  put<T, R>(path: string, payload: T, apiRoot: string): Observable<R> {
    return this.httpClient.put<R>(apiRoot + path, payload, this.getOptions());
  }

  patch<T, R>(path: string, payload: T, apiRoot: string): Observable<R> {
    return this.httpClient.patch<R>(apiRoot + path, payload, this.getOptions());
  }

  public getDeleteOptions(body) {
    return {
      headers: {
        Authorization: 'Bearer ' + this.session?.idToken?.jwtToken,
        Accept: 'application/json',
        'id-token': '' + this.session?.idToken?.jwtToken, // TODO: Clean this one up a bit
        id_token: '' + this.session?.idToken?.jwtToken, // TODO: Clean this one up a bit
      },
      body,
    };
  }

  public getOptions() {
    return {
      headers: {
        Authorization: 'Bearer ' + this.session?.idToken?.jwtToken,
        'id-token': '' + this.session?.idToken?.jwtToken, // TODO: Clean this one up a bit
        id_token: '' + this.session?.idToken?.jwtToken, // TODO: Clean this one up a bit
        Accept: 'application/json',
        'Content-Type':'application/json',
      },
    };
  }
}
