import { Injectable } from '@angular/core';
import { SectionGuard } from './section-guard.service';
import { AuthService, UserSession } from '../services/auth.service';
import { PermissionsService } from '../../admin/services/permissions.service';
import { concatMap } from 'rxjs/operators';
import { ModulePermissionPrefix } from './module-permission-prefix.const';

@Injectable({
  providedIn: 'root',
})
export class ReleaseManagementGuard extends SectionGuard {
  constructor(
    private authService: AuthService,
    permissionsService: PermissionsService
  ) {
    super(authService, permissionsService, [ModulePermissionPrefix.RELEASE]);
  }
}
