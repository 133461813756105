export const HOSTNAME = window.location.hostname.includes('localhost')
  ? 'dev.o3.inspire.direct'
  : window.location.hostname;

export const environment = {
  production: true,
  // clientId: HOSTNAME_ENV_MAPPING[HOSTNAME].clientId,
  // userPoolId: HOSTNAME_ENV_MAPPING[HOSTNAME].userPoolId,
  // domainName: HOSTNAME_ENV_MAPPING[HOSTNAME].domainName,
  name: 'Test',
  tenantId: 'DB',
  api: {
    docgen: `https://dg.${HOSTNAME}/docgen/`,
    docComparison: `https://pd.${HOSTNAME}/diff/`,
    o3: `https://um.${HOSTNAME}/users/`,
    productCapture: `https://lm.${HOSTNAME}/lifecycle/`,
    schedule: `https://sc.${HOSTNAME}/schedule/`,
    workflow: `https://wf.${HOSTNAME}/workflow/`,
    staticData: `https://sd.${HOSTNAME}/staticdata/`,
    analyticsService: `https://as.${HOSTNAME}/analytics/`,
    releaseManagement: `https://rmb.${HOSTNAME}/release/`,
    releaseManagementWss: `wss://rmb-wss.${HOSTNAME}/`,
    dataIngestion: `https://dipr.${HOSTNAME}/pipeline-runner/`,
    dataEnrichment: `https://disde.${HOSTNAME}/di-staticdata/`,
    rulesProcessor: `https://rp.${HOSTNAME}/rulesprocessor/`,
    productData: `https://pdata.${HOSTNAME}/product-data/`,
  },
};
