import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from '../model/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUser: Observable<UserDetails | undefined> =
    this.authService.onUserSession.pipe(
      map((userDetails) => {
        const session = userDetails;
        let result: UserDetails = undefined;

        if (session?.isValid()) {
          const email = session.idToken.payload.email;
          const groups = session.idToken.payload['cognito:groups'] || [];
          result = {
            id: session.accessToken.payload.username,
            name: session.idToken.payload.name,
            email,
            type: 'unknown',
            groups,
            permissions: CurrentUserService.getUserPermissions(
              session.idToken.payload.name
            ),
            services: CurrentUserService.getPlatformServices(),
          };
          if (
            email.endsWith('@deltacapita.com') ||
            email.endsWith('@ext.deltacapita.com')
          ) {
            result.type = 'inspire';
          } else {
            result.type = 'distributor';
          }
        }
        return result;
      })
    );

  constructor(private authService: AuthService) {}

  private static getUserPermissions(name) {
    return [
      {
        key: 'GET_HELLO_WORLD',
        name: 'User can get generic message',
        description: '',
        requiredPermissions: [],
        serviceKey: 'HelloWorld',
      },
      {
        key: 'GET_HELLO_USER',
        name: 'User can get only its message',
        description: '',
        requiredPermissions: ['GET_HELLO_WORLD'],
        serviceKey: 'HelloWorld',
      },
    ];
  }

  private static getPlatformServices() {
    return [
      {
        key: 'UserManagement',
        name: 'User Management Service',
        description: 'Just say hello',
        isEnable: true,
      },
      {
        key: 'HelloWorld',
        name: 'Hello World',
        description: 'Just say hello',
        isEnable: true,
      },
    ];
  }
}
