import { Injectable } from '@angular/core';
import { SectionGuard } from './section-guard.service';
import { PermissionsService } from '../../admin/services/permissions.service';
import { AuthService } from '../services/auth.service';
import { ModulePermissionPrefix } from './module-permission-prefix.const';

@Injectable({
  providedIn: 'root',
})
export class StaticDataGuard extends SectionGuard {
  constructor(
    authService: AuthService,
    permissionsService: PermissionsService
  ) {
    super(authService, permissionsService, [
      ModulePermissionPrefix.STATIC_DATA,
    ]);
  }
}
